<template>
    <ui-data-table 
        :p-headers="headers"
        p-title="Opções de Grid"
        p-read-link="gridOption.read.one"
        p-create-link="gridOption.create"
        p-name="gridOptions"
    />
</template>
<script>
import {uiDataTable} from '@/components/ui'
export default {
    name: 'vwGridOptionRead',
    components: {
        uiDataTable  
    },
    data(){
        return {
            headers: [{
                text: 'id',
                value: 'id'
            }, {
                text: 'Descrição',
                value: 'descricao'
            }, {
                text: 'Cadastrado',
                value: 'criado_em'
            }, {
                text: 'Ações', 
                value: 'actions', 
                sortable: false
            }],
        }
    },
}
</script>